import React from 'react';

import { formatterWithCents } from 'src/utils/currencyFormatter';

import _themeFormStyles from '../../forms/forms.module.scss';
import _themeOrderFormStyles from '../../order-forms/order-form.module.scss';
import _themeTextStyles from '../../text/text.module.scss';
import _shippingStyles from './shipping.module.scss';
import _utils from '../../../styles/utils.module.scss';

const FreeShipping = ({ name, register, lowestRate, coupon }) => {
  return (
    <div className={`${_themeFormStyles.field} ${_themeOrderFormStyles.field}`}>
      <div
        className={`${_themeFormStyles.input} ${_shippingStyles.shippingMethod}`}
      >
        <div className={`${_utils.flexRow}`}>
          <div
            className={`${_utils.flexRow} ${_utils.flexGrow1} ${_utils.flexJustifyBetween} ${_utils.pl16x} ${_utils.pr16x}`}
          >
            <div className={`${_utils.flexColumn} ${_utils.flexGrow1} ${_utils.flexJustifyCenter} ${_utils.flexAlignCenter}`}>
              <input type="hidden" name={name} ref={register} />

              <strong className={`${_utils.flex} ${_utils.flexJustifyEnd}`}>
                <span className={`${_themeTextStyles.strikethrough}`}>
                  {formatterWithCents.format(lowestRate.shipping_amount.amount)}
                </span>{' '}
              </strong>
              <strong>
                <span className={`${_themeTextStyles.redColor}`}>
                  FREE SHIPPING!
                </span>
              </strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FreeShipping;
