import React from 'react';
import PropTypes from 'prop-types';

import _themeOrderSummaryStyles from './order-summary.module.scss';
import _themeTextStyles from '../../text/text.module.scss';

const LineItem = ({ name, price, coupon }) => {
  return (
    <div className={`${_themeOrderSummaryStyles.lineItem}`}>
      <div className={`${_themeOrderSummaryStyles.lineItemTitle}`}>{name}</div>
      <div className={`${_themeOrderSummaryStyles.lineItemPrice}`}>
        {Boolean(coupon) ? (
          <>
            <span className={`${_themeTextStyles.strikethrough}`}>{price}</span>
            <br/>
            <span className={`${_themeTextStyles.redColor}`}>{coupon.name}</span>
          </>
        ) : (
          price
        )}
      </div>
    </div>
  );
};

LineItem.propTypes = {
  title: PropTypes.string,
  price: PropTypes.string.isRequired,
  coupon: PropTypes.shape({
    name: PropTypes.string,
  }),
};

LineItem.defaultProps = {
  title: 'Total',
};

export default LineItem;
