import React from 'react';
import PropTypes from 'prop-types';

import _customFormStyles from './order-bump.module.scss';
import _utils from '../../../styles/utils.module.scss';

const OrderBump = ({
  disabled,
  register,
  name,
  renderHeadline,
  renderSubHeadline,
}) => {
  return (
    <div className={`${_customFormStyles.orderBump}`}>
      <div
        className={`${_utils.textCenter} ${_customFormStyles.orderBumpField}`}
      >
        <i className={`fas fa-arrow-right`}></i>
        <input type="checkbox" name={name} ref={register} disabled={disabled} />{' '}
        {renderHeadline()}
      </div>
      <div>{renderSubHeadline()}</div>
    </div>
  );
};

OrderBump.propTypes = {
  isChecked: PropTypes.bool,
  handleChange: PropTypes.func,
  renderHeadline: PropTypes.func,
  renderSubHeadline: PropTypes.func,
  disabled: PropTypes.bool
};

OrderBump.defaultProps = {
  isChecked: false,
  disabled: false,
  handleChange: () => {},
  renderHeadline: () => {},
  renderSubHeadline: () => {},
};

export default OrderBump;
