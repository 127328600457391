import React from 'react';

import OrderBump from 'gatsby-theme-kalanso-ui/src/components/order-forms/product-select/OrderBump';
import Underlined from 'gatsby-theme-kalanso-ui/src/components/text/Underlined';

import currencyFormatter from '../../../utils/currencyFormatter';

import _themeFormStyles from 'gatsby-theme-kalanso-ui/src/components/forms/forms.module.scss';
import _themeTextStyles from 'gatsby-theme-kalanso-ui/src/components/text/text.module.scss';
import _utils from 'gatsby-theme-kalanso-ui/src/styles/utils.module.scss';

// Product name should be the same for test/live stripe products.
const orderBumpsByName = {
  "Biochar Video Crash Course": {
    render: ({ price, name, register, disabled }) => {
      return (
        <OrderBump
          name={`${name}[${price.id}]`}
          register={register}
          disabled={disabled}
          renderHeadline={() => <>YES! Give Me The Video Crash Course, TOO!</>}
          renderSubHeadline={() => (
            <>
              <Underlined
                className={`${_themeTextStyles.redColor} ${_utils.fontWeight600}`}
              >
                ONE-TIME OFFER, ONLY{' '}
                {currencyFormatter.format(price.unit_amount / 100)}
              </Underlined>
              <br />
              <br />
              Want the video crash course I made to help you use
              biochar with confidence? It's gonna answer the most common
              questions gardeners have and show you how to incorporate biochar
              into your garden fast and easy.
              <br />
              <br />
              Click YES to add this to your order now for just{' '}
              {currencyFormatter.format(price.unit_amount / 100)}! (This offer
              is not available at ANY other time or place)
            </>
          )}
        />
      );
    },
  },
};

const OrderBumpsByName = ({ prices, register, name, disabled }) => {
  console.log(prices)
  return prices.map((price) => {
    return (
      <div key={price.id} className={`${_themeFormStyles.fieldset}`}>
        {orderBumpsByName[price.product.name].render({
          price,
          name,
          register,
          disabled,
        })}
      </div>
    );
  });
};

export default OrderBumpsByName;
