import { useCallback, useState } from 'react';

import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';

import { usePreviousBlob } from 'kalanso-plugin-hooks';

const createPaymentIntent = async (
  postBodyString,
  token,
  stripe,
  stripeElements,
) => {
  const response = await fetch('/api/complete-checkout', {
    method: 'POST',
    body: postBodyString,
    headers: {
      authorization: `Bearer ${token}`,
    },
  });

  const { paymentIntent: { client_secret } } = await response.json();

  const cardElement = stripeElements.getElement(CardElement);

  return await stripe.confirmCardPayment(client_secret, {
    payment_method: { card: cardElement },
  });
};

const useStripeCheckout = ({
  postData = {},
  token = ``,
  onPaymentError = () => {},
  onPaymentSuccess = () => {},
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const stripe = useStripe();
  const stripeElements = useElements();

  const [, postBodyString] = usePreviousBlob(postData);

  const completeCheckout = useCallback(() => {
    const run = async () => {
      if (!isSubmitting) {
        setIsSubmitting(true);

        const { error, paymentIntent } = await createPaymentIntent(
          postBodyString,
          token,
          stripe,
          stripeElements,
        );

        if (error) {
          onPaymentError(error);
          setIsSubmitting(false);
        } else if (paymentIntent.status === 'succeeded') {
          onPaymentSuccess(paymentIntent);
        } else {
          console.error(`Unknown PI status: ${paymentIntent.status}`);
          setIsSubmitting(false);
        }
      }
    };

    run();
  }, [
    postBodyString,
    token,
    stripe,
    stripeElements,
    isSubmitting,
    setIsSubmitting,
    onPaymentSuccess,
    onPaymentError,
  ]);

  return {
    completeCheckout,
    isSubmitting,
  };
};

export default useStripeCheckout;
