import React, { useEffect } from 'react';

import { CardElement } from '@stripe/react-stripe-js';

import useOrderForm from './useOrderForm';
import { STEPS } from './OrderFormProvider';

import { useForm } from 'react-hook-form';
import { useTrackingCodes } from 'kalanso-plugin-hooks';

import { navigate } from 'gatsby';

import useShippingRatesApi from 'src/hooks/useShippingRatesApi';

import useStripeProducts from 'src/hooks/useStripeProducts';
import useCustomerToken from 'src/hooks/useCustomerToken';
import useStripeCheckout from 'src/hooks/useStripeCheckout';

import { canUseDOM } from 'kalanso-plugin-utils';

import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';

import LinkButton from 'gatsby-theme-kalanso-ui/src/components/forms/buttons/LinkButton';
import OrderSummary from 'gatsby-theme-kalanso-ui/src/components/order-forms/order-summary/OrderSummary';
import CreditCard from 'gatsby-theme-kalanso-ui/src/components/order-forms/credit-card/CreditCard';
import Submit from 'gatsby-theme-kalanso-ui/src/components/forms/inputs/Submit';

import ShippingSelect from 'gatsby-theme-kalanso-ui/src/components/order-forms/shipping-select/ShippingSelect';
import FreeShipping from 'gatsby-theme-kalanso-ui/src/components/order-forms/shipping-select/FreeShipping';

import FormProgressBar from './FormProgressBar';
import OrderBumpsByName from './OrderBumpsByName';
import CreditCardLogos from './CreditCardLogos';

import _reviewBlockStyles from './review-block.module.scss';
import _themeFormStyles from 'gatsby-theme-kalanso-ui/src/components/forms/forms.module.scss';
import _buttonStyles from 'gatsby-theme-kalanso-ui/src/components/forms/buttons/buttons.module.scss';
import _utils from 'gatsby-theme-kalanso-ui/src/styles/utils.module.scss';
import _progressStyles from './progress.module.scss';

const schema = Joi.object({
  shippingRateId: Joi.string().required(),
  orderBumpsById: Joi.object().optional(),
  paymentComplete: Joi.string().valid('true').required(),
});

const CompleteCheckoutForm = () => {
  const removeDefaultValues = (key) => {
    if (canUseDOM()) {
      window.localStorage.removeItem(key);
    }
  };

  const { orderBumps, prices } = useStripeProducts();
  const { token } = useCustomerToken();

  const [state, actions] = useOrderForm();

  const { transitionTo } = actions;
  const { coupon, isEligibleForFreeShipping } = state;
  const {
    INITIATE_CHECKOUT: { priceId, billing, shipping },
  } = state.formValues;

  const trackingCodes = useTrackingCodes();

  const formMethods = useForm({
    resolver: joiResolver(schema),
    mode: 'onBlur',
  });

  const { setValue, setError } = formMethods;

  const formValues = formMethods.watch();

  const { shippingRateId, orderBumpsById } = formValues;

  const selectedProduct = (prices.find((price) => price.id === priceId) || {})
    .product;
  const selectedProductId = (selectedProduct || {}).id;

  const shippingApi = useShippingRatesApi({
    shipTo: {
      ...(Boolean(shipping) ? shipping : billing),
    },
    productId: selectedProductId,
    token,
  });

  const shippingRates = shippingApi.rates;
  const couponId = (coupon || {}).id;
  const shouldDisplayFreeShippingView =
    isEligibleForFreeShipping && shippingApi.rates.length > 0;

  const { completeCheckout, isSubmitting } = useStripeCheckout({
    postData: {
      shipTo: Boolean(shipping) ? shipping : billing,
      shippingRateId,
      couponId,
      priceIds: [
        ...prices
          .filter((price) => price.id === priceId)
          .map((price) => price.id),
        ...orderBumps
          .filter((price) => (orderBumpsById || {})[price.id])
          .map((price) => price.id),
      ].filter(Boolean),
      metadata: {
        ...trackingCodes,
      },
    },
    token,
    onPaymentError: (error) => {
      setError('server', {
        type: 'manual',
        message: error.message,
      });
    },
    onPaymentSuccess: (paymentIntent) => {
      removeDefaultValues('__INITIATE_CHECKOUT__');
      navigate(`/success/you-rock/${paymentIntent.id}`);
    },
  });

  useEffect(() => {
    if (coupon && coupon.valid && shippingRates.length > 0) {
      setValue('shippingRateId', shippingRates[0].rate_id, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  }, [coupon, shippingRates, setValue]);

  // Scroll to top of page when this component mounts
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  // If we're:
  // 1. submitting the form
  // 2. fetching shipping rates...
  const isFormDisabled = isSubmitting || shippingApi.pending;

  return (
    <>
      <div
        className={`${_progressStyles.headlineContainer} ${_utils.pl16x} ${_utils.pr16x}`}
      >
        <h1 className={`${_progressStyles.headline}`}>
          STEP #2: Your FINAL Step!
        </h1>
      </div>

      <FormProgressBar />

      <form
        className={`${_utils.mb64x}`}
        onSubmit={formMethods.handleSubmit(completeCheckout)}
      >
        {/* Order Review Block */}
        <div className={`${_themeFormStyles.fieldset} ${_utils.mt32x}`}>
          <div className={`${_reviewBlockStyles.box}`}>
            <div className={`${_reviewBlockStyles.block}`}>
              <div className={`${_reviewBlockStyles.inner}`}>
                <div className={`${_reviewBlockStyles.label}`}>Product</div>
                <div className={`${_reviewBlockStyles.content}`}>
                  <span>{selectedProduct.name}</span>
                </div>
              </div>
              <div className={`${_reviewBlockStyles.link}`}>
                <button
                  className={`${_buttonStyles.asLink}`}
                  type="button"
                  onClick={() => transitionTo(STEPS.INITIATE_CHECKOUT)}
                >
                  Change
                </button>
              </div>
            </div>

            <div className={`${_reviewBlockStyles.block}`}>
              <div className={`${_reviewBlockStyles.inner}`}>
                <div className={`${_reviewBlockStyles.label}`}>Contact</div>
                <div className={`${_reviewBlockStyles.content}`}>
                  <span>{billing.email}</span>
                </div>
              </div>
              <div className={`${_reviewBlockStyles.link}`}>
                <button
                  className={`${_buttonStyles.asLink}`}
                  type="button"
                  onClick={() => transitionTo(STEPS.INITIATE_CHECKOUT)}
                >
                  Change
                </button>
              </div>
            </div>

            <div className={`${_reviewBlockStyles.block}`}>
              <div className={`${_reviewBlockStyles.inner}`}>
                <div className={`${_reviewBlockStyles.label}`}>Ship To</div>
                <div className={`${_reviewBlockStyles.content}`}>
                  {(Boolean(shipping)
                    ? [
                        shipping.line1,
                        shipping.line2,
                        shipping.city,
                        shipping.state,
                        shipping.zip,
                      ]
                    : [
                        billing.line1,
                        billing.line2,
                        billing.city,
                        billing.state,
                        billing.zip,
                      ]
                  )
                    .filter(Boolean)
                    .join(', ')}
                </div>
              </div>
              <div className={`${_reviewBlockStyles.link}`}>
                <button
                  className={`${_buttonStyles.asLink}`}
                  type="button"
                  onClick={() => transitionTo(STEPS.INITIATE_CHECKOUT)}
                >
                  Change
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Shipping Rates */}
        <div className={`${_themeFormStyles.fieldset}`}>
          <div className={`${_themeFormStyles.fieldsetHeading}`}>
            Shipping Method
          </div>

          {shouldDisplayFreeShippingView ? (
            <FreeShipping
              name="shippingRateId"
              lowestRate={shippingRates[0]}
              coupon={coupon}
              register={formMethods.register}
            />
          ) : (
            <ShippingSelect
              name="shippingRateId"
              shippingApi={shippingApi}
              register={formMethods.register}
              errors={formMethods.errors}
              disabled={isFormDisabled}
              isEligibleForFreeShipping={isEligibleForFreeShipping}
            />
          )}
        </div>

        {/* Credit Card */}
        <div className={`${_themeFormStyles.fieldset}`}>
          <div
            className={`${_themeFormStyles.fieldsetHeading} ${_themeFormStyles.withSubheading} ${_utils.pt0}`}
          >
            Payment Method
          </div>
          <div className={`${_themeFormStyles.fieldsetSubheading}`}>
            Powered By Stripe
          </div>
          <CreditCard
            CardElement={CardElement}
            register={formMethods.register}
            setError={formMethods.setError}
            clearErrors={formMethods.clearErrors}
            setValue={formMethods.setValue}
            errors={[
              formMethods.errors['paymentMethod'],
              formMethods.errors['paymentComplete'],
            ].filter(Boolean)}
          />
        </div>

        <div className={`${_themeFormStyles.fieldset}`}>
          <CreditCardLogos />
        </div>

        {/* Total Price Being Charged (plus shipping) */}
        <OrderSummary
          selectedProducts={prices.filter((price) => price.id === priceId)}
          selectedOrderBumps={orderBumps.filter(
            (price) => orderBumpsById && orderBumpsById[price.id],
          )}
          selectedShippingRates={shippingRates.filter(
            (rate) => rate.rate_id === formValues['shippingRateId'],
          )}
          coupons={[coupon].filter(Boolean)}
        />

        {/* Order Bumps */}
        <OrderBumpsByName
          prices={orderBumps}
          name="orderBumpsById"
          register={formMethods.register}
          disabled={isFormDisabled}
        />

        {/* Error messages from our API */}
        {formMethods.errors.server && (
          <div className={`${_themeFormStyles.fieldset} ${_utils.mt32x}`}>
            <div className={`${_utils.pr16x} ${_utils.pl16x}`}>
              <div
                className={`${_themeFormStyles.errors} ${_utils.pt16x} ${_utils.pb16x}`}
              >
                {formMethods.errors.server.message ? (
                  <p className={`${_themeFormStyles.errorMessage}`}>
                    {formMethods.errors.server.message}
                  </p>
                ) : (
                  <>
                    <p className={`${_themeFormStyles.errorMessage}`}>
                      Ohh no! Something went wrong. Please refresh your browser
                      and try again. If the problem persists, send an email to
                      info@kalanso.com with details about your issue.
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        )}

        {/* Submit Button */}
        <div className={`${_themeFormStyles.fieldset} ${_utils.mt32x}`}>
          <Submit
            disabled={isFormDisabled}
            pending={isSubmitting}
            pendingText="We're dotting the i's..."
            pendingSubtext="Don't leave till we confirm your order (~10 seconds)..."
            idleText="YES! COMPLETE ORDER!"
            idleIcon="fa-shopping-cart"
            idleSubtext="I Want To Grow Whatever I Want With Confidence!"
          />

          {!isFormDisabled && (
            <div
              className={`${_utils.mt8x} ${_utils.mb32x} ${_utils.textCenter}`}
            >
              <LinkButton type="submit" className={`${_utils.fontSize500}`}>
                <strong>YES! COMPLETE ORDER!</strong>
              </LinkButton>
            </div>
          )}
        </div>
      </form>
    </>
  );
};

export default CompleteCheckoutForm;
