import React from 'react';

import useOrderForm from './useOrderForm';

import _progressStyles from './progress.module.scss';
import _utils from 'gatsby-theme-kalanso-ui/src/styles/utils.module.scss';

const FormProgressBar = () => {
  const [state] = useOrderForm();

  const { step } = state;

  return (
    <div
      className={`${_progressStyles.container} ${_utils.pl16x} ${_utils.pr16x}`}
    >
      <div
        className={`${_progressStyles.step} ${_progressStyles.first} ${
          step === 0 ? _progressStyles.active : ''
        }`}
      >
        <div className={`${_progressStyles.bulletContainer} ${_utils.mr8x}`}>
          <span className={`${_progressStyles.bullet}`}>1</span>
        </div>
        <div className={`${_utils.flexColumn} ${_utils.flexAlignStart} ${_utils.flexJustifyCenter}`}>
          <p className={`${_progressStyles.stepTitle}`}>Product & Contact</p>
          <small
            className={`${_progressStyles.stepSubTitle} ${_utils.visibleAboveTablet}`}
          >
            BUCKETS ready to ship...
          </small>
        </div>
      </div>
      <div
        className={`${_progressStyles.step} ${_progressStyles.second} ${
          step === 1 ? _progressStyles.active : ''
        }`}
      >
        <div className={`${_progressStyles.bulletContainer} ${_utils.mr8x}`}>
          <span className={`${_progressStyles.bullet}`}>2</span>
        </div>
        <div className={`${_utils.flexColumn} ${_utils.flexAlignStart} ${_utils.flexJustifyCenter}`}>
          <p className={`${_progressStyles.stepTitle}`}>
            Upgrade?
          </p>
          <small
            className={`${_progressStyles.stepSubTitle} ${_utils.visibleAboveTablet}`}
          >
            Let's make it official...
          </small>
        </div>
      </div>
    </div>
  );
};

export default FormProgressBar;
