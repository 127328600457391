import React from 'react';

import _utils from 'gatsby-theme-kalanso-ui/src/styles/utils.module.scss';

const CreditCardLogos = () => {
  return (
    <>
      <p
        className={`${_utils.ml32x} ${_utils.mr32x} ${_utils.textCenter} ${_utils.fontSize200}`}
      >
        <i className={`fab fa-cc-stripe ${_utils.mr8x}`}></i>
        <i className={`fab fa-cc-visa ${_utils.mr8x}`}></i>
        <i className={`fab fa-cc-mastercard ${_utils.mr8x}`}></i>
        <i className={`fab fa-cc-amex ${_utils.mr8x}`}></i>
        <i className="fab fa-cc-discover"></i>
      </p>
      <p className={`${_utils.ml32x} ${_utils.mr32x} ${_utils.textCenter}`}>
        All transactions are 100% safe and secure. Encrypted by 256 bit SSL
        Technology.
      </p>
    </>
  );
};

export default CreditCardLogos;
