import React, { createContext, useEffect, useReducer } from 'react';

import useCouponApi from 'src/hooks/useCouponApi';

export const OrderFormContext = createContext();

export const ACTIONS = {
  STEP: 'STEP',
  SET_FORM_VALUES: 'SET_FORM_VALUES',
  SET_COUPON: 'SET_COUPON',
};

export const STEPS = {
  INITIATE_CHECKOUT: 0,
  COMPLETE_CHECKOUT: 1,
};

const initialState = {
  step: STEPS.INITIATE_CHECKOUT,
  formValues: {
    /**
     * INITIATE_CHECKOUT: {
     *  priceId: "123",
     *  shipping: {
     *    line1: "123 Main St.",
     *  }
     * }
     */
  },
};

const reducer = (state, action) => {
  if (action.type === ACTIONS.STEP) {
    return { ...state, step: action.step };
  }

  if (action.type === ACTIONS.SET_COUPON) {
    return {
      ...state,
      coupon: action.coupon,
      isEligibleForFreeShipping:
        action.coupon &&
        action.coupon.valid &&
        action.coupon.metadata.scope === 'shipping' &&
        action.coupon.percent_off === 100,
    };
  }

  if (action.type === ACTIONS.SET_FORM_VALUES) {
    return {
      ...state,
      formValues: {
        ...state.formValues,
        [action.step]: action.values,
      },
    };
  }

  return state;
};

const OrderFormProvider = ({ children }) => {
  const { coupon } = useCouponApi();

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (coupon) {
      dispatch({ type: ACTIONS.SET_COUPON, coupon });
    }
  }, [coupon]);

  return (
    <OrderFormContext.Provider value={[state, dispatch]}>
      {children({
        currentStep: state.step,
        isEligibleForFreeShipping: state.isEligibleForFreeShipping,
      })}
    </OrderFormContext.Provider>
  );
};

export default OrderFormProvider;
