import React from 'react';

import StripeLayout from 'src/components/StripeLayout';
import OrderPage from 'src/components/pages/order/OrderPage';

import SEO from 'src/components/SEO';

const GoPage = () => {
  return (
    <>
      <SEO title={'Take Action'} hideFromSEO={true} />

      <StripeLayout showJunkDrawer={false}>
        <OrderPage />
      </StripeLayout>
    </>
  );
};

export default GoPage;
