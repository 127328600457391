import React from 'react';
import PropTypes from 'prop-types';

import TextField from '../../forms/inputs/TextField';
import SelectInput from '../../forms/inputs/SelectInput';

import useUnitedStates from '../../../hooks/useUnitedStates';

const AddressFormFields = ({
  formValues,
  register,
  errors,
  firstName,
  lastName,
  city,
  state,
  country,
  zip,
  line1,
  line2,
}) => {

  const unitedStates = useUnitedStates();

  return (
    <>
      {firstName ? (
        <TextField
          label="First Name *"
          id={`address-${firstName}`}
          name={firstName}
          register={register}
          errors={errors}
          values={formValues}
        />
      ) : null}

      {lastName ? (
        <TextField
          label="Last Name *"
          id={`address-${lastName}`}
          name={lastName}
          register={register}
          errors={errors}
          values={formValues}
        />
      ) : null}

      <TextField
        label="Address *"
        id={`address-${line1}`}
        name={line1}
        register={register}
        errors={errors}
        values={formValues}
      />

      <TextField
        label="Apartment, unit, suite, etc... (optional)"
        id={`address-${line2}`}
        name={line2}
        register={register}
        errors={errors}
        values={formValues}
      />

      <TextField
        label="City *"
        id={`address-${city}`}
        name={city}
        register={register}
        errors={errors}
        values={formValues}
      />

      <SelectInput
        label="State *"
        id={`address-${state}`}
        name={state}
        register={register}
        errors={errors}
        values={formValues}
        options={unitedStates}
      />

      <TextField
        label="Zip Code*"
        id={`address-${zip}`}
        name={zip}
        register={register}
        errors={errors}
        values={formValues}
      />

      <TextField
        label="Country *"
        id={`address-${country}`}
        name={country}
        register={register}
        readOnly={true}
        errors={errors}
        values={formValues}
        defaultValue="US"
      />
    </>
  );
};

AddressFormFields.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  line1: PropTypes.string.isRequired,
  line2: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  zip: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
};

export default AddressFormFields;
