import React from 'react';
import PropTypes from 'prop-types';

import _themeFormStyles from '../forms.module.scss';
import _themeOrderFormStyles from '../../order-forms/order-form.module.scss';

import formatClassNames from '../../../utils/formatClassNames';

import { ErrorMessage } from '@hookform/error-message';
import { get } from 'react-hook-form';

const getTextFieldCSS = (name, values, errors) => {
  const err = get(errors, name);
  const val = get(values, name);

  return formatClassNames([
    _themeFormStyles.input,
    err ? _themeFormStyles.invalid : '',
    !err && Boolean(val) ? _themeFormStyles.valid : '',
  ]);
};

const SelectInput = ({
  id,
  name,
  label,
  options,
  fieldCss,
  labelCss,
  ...rest
}) => {
  const { errors, values, register, ...inputProps } = rest;
  const registerRef = Boolean(register) ? register : null;

  return (
    <div
      className={`${_themeFormStyles.field} ${_themeOrderFormStyles.field} ${fieldCss}`}
    >
      <label htmlFor={id} className={`${_themeFormStyles.label} ${labelCss}`}>
        {label}
      </label>

      <select
        id={id}
        name={name}
        ref={registerRef}
        className={getTextFieldCSS(name, values, errors)}
        {...inputProps}
      >
        {options.map((option, index) => (
          <option key={index} value={option.value}>{option.label}</option>
        ))}
      </select>
      <div className={`${_themeFormStyles.errorMessage}`}>
        <ErrorMessage errors={errors} name={name} />
      </div>
    </div>
  );
};

SelectInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  fieldCss: PropTypes.string,
  labelCss: PropTypes.string,
};

SelectInput.defaultProps = {
  type: 'text',
  fieldCss: '',
  labelCss: '',
};

export default SelectInput;
