import React from 'react';

import _styles from 'src/components/pages/order/testimonial-stack.module.scss';

const Testimonial = ({ name, Commentary }) => {
  return (
    <li className={`${_styles.listItem}`}>
      <div className={`${_styles.listContent}`}>
        <div className={`${_styles.quote}`}>
          <i className="fas fa-quote-left"></i>
        </div>
        <div>
          <h3 className={`${_styles.name}`}>{name}</h3>
          <p className={`${_styles.testimonial}`}>
            <Commentary />
          </p>
        </div>
      </div>
    </li>
  );
};

export default Testimonial;
