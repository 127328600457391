import { useEffect } from 'react';

import { useFetch } from 'kalanso-plugin-hooks';

const useShippingRatesApi = ({ token, ...shippingDetails }) => {
  const [{ idle, success, pending, errors, payload }, getRates] = useFetch(
    '/api/get-shipping-rates',
    {
      method: 'POST',
      body: JSON.stringify(shippingDetails),
      headers: {
        authorization: `Bearer ${token}`,
      },
    },
  );

  useEffect(() => {
    if (token) {
      getRates();
    }
  }, [token, getRates]);

  return {
    idle,
    success,
    pending,
    errors,
    rates: Boolean(payload) ? payload.rates : [],
  };
};

export default useShippingRatesApi;
