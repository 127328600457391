import { graphql, useStaticQuery } from 'gatsby';

import currencyFormatter from '../utils/currencyFormatter';

const useStripeProducts = () => {
  const priceData = useStaticQuery(graphql`
    query Query {
      primary: allStripePrice(
        filter: {
          product: {
            metadata: {
              funnel_id: { regex: "/biocharwebinar/" }
              offer_type: { eq: "primary" }
            }
          }
        }
      ) {
        edges {
          node {
            id
            unit_amount
            unit_amount_decimal
            type
            currency
            active
            product {
              id
              images
              description
              name
              unit_label
              type
              metadata {
                funnel_id
                items
                offer_type
                popularity
                product_type
                sort_order
                weight
              }
            }
          }
        }
      }
      orderBumps: allStripePrice(
        filter: {
          product: {
            metadata: {
              funnel_id: { regex: "/biocharwebinar/" }
              offer_type: { eq: "orderbump" }
            }
          }
        }
      ) {
        edges {
          node {
            id
            unit_amount
            unit_amount_decimal
            type
            currency
            active
            product {
              id
              images
              description
              name
              unit_label
              type
              metadata {
                funnel_id
                items
                offer_type
                popularity
                product_type
                sort_order
                weight
              }
            }
          }
        }
      }
    }
  `);

  // const allOrderBumps = priceData.orderBumps.edges.map((edge) => {
  //   return {
  //     ...edge.node,
  //     formattedUnitAmount: currencyFormatter.format(
  //       edge.node.unit_amount / 100,
  //     ),
  //   };
  // });

  const allPrimaryOffers = priceData.primary.edges.map((edge) => {
    return {
      ...edge.node,
      formattedUnitAmount: currencyFormatter.format(
        edge.node.unit_amount / 100,
      ),
      isMostPopular:
        edge.node.product.metadata.popularity &&
        edge.node.product.metadata.popularity === '1',
    };
  });

  // Highest price to lowest price...
  const sortLowToHigh = (a, b) => {
    if (a.unit_amount < b.unit_amount) {
      return -1;
    } else if (a.unit_amount > b.unit_amount) {
      return 1;
    } else {
      return 0;
    }
  };

  return {
    prices: allPrimaryOffers.sort(sortLowToHigh),
    // orderBumps: allOrderBumps,
    orderBumps: [],
  };
};

export default useStripeProducts;
