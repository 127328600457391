import 'gatsby-theme-kalanso-ui/src/styles/global.scss';

import React from 'react';
import PropTypes from 'prop-types';

import Footer from 'gatsby-theme-kalanso-ui/src/components/footer/Footer';

import { Elements } from '@stripe/react-stripe-js';

import getStripe from '../utils/getStripe';

const StripeLayout = ({ children, showJunkDrawer }) => {
  return (
    <Elements stripe={getStripe()}>
      <main>{children}</main>
      {showJunkDrawer && <Footer />}
    </Elements>
  );
};

StripeLayout.propTypes = {
  children: PropTypes.node.isRequired,
  showJunkDrawer: PropTypes.bool,
};

StripeLayout.defaultProps = {
  showJunkDrawer: true,
};

export default StripeLayout;
