import React from 'react';
import PropTypes from 'prop-types';

import { ErrorMessage } from '@hookform/error-message';
// import { get } from 'react-hook-form';

import Table from '../../table/Table';
import TableHeader from '../../table/TableHeader';

import ProductOption from './ProductOption';

import _themeFormStyles from '../../forms/forms.module.scss';
import _themeOrderSelectStyles from './product-select.module.scss';
import _utils from '../../../styles/utils.module.scss';

const ProductSelect = ({
  name,
  prices,
  register,
  errors,
  disabled
}) => {
  return (
    <>
      <Table>
        <TableHeader
          className={`${_themeOrderSelectStyles.header}`}
          columns={['Products', 'Price']}
        />
        {prices.map((price) => (
          <ProductOption
            key={`price-${price.id}`}
            name={name}
            price={price}
            register={register}
            disabled={disabled}
          />
        ))}
      </Table>
      <div
        id={`${name}-error-message`}
        className={`${_themeFormStyles.errorMessage} ${_utils.pl32x}`}
      >
        <ErrorMessage errors={errors} name={name} />
      </div>
    </>
  );
};

ProductSelect.propTypes = {
  prices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ),
  name: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    message: PropTypes.string,
  }),
  readOnly: PropTypes.bool
};

ProductSelect.defaultProps = {
  prices: [],
  readOnly: false
};

export default ProductSelect;
