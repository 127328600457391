import React from 'react';
import PropTypes from 'prop-types';

import { formatterWithCents } from '../../../utils/currencyFormatter';

import LineItem from './LineItem';
import OrderTotal from './OrderTotal';

import _themeFormStyles from '../../forms/forms.module.scss';

const OrderSummary = ({
  selectedProducts,
  selectedOrderBumps,
  selectedShippingRates,
  coupons,
}) => {
  const totalPrice = () => {
    let total = 0;

    total += selectedProducts.reduce((p, c) => p + c.unit_amount, 0);
    total += selectedOrderBumps.reduce((p, c) => p + c.unit_amount, 0);

    const shipping = selectedShippingRates.reduce(
      (p, c) => p + c.shipping_amount.amount,
      0,
    );

    return total / 100 + shipping;
  };

  if (
    selectedProducts.length === 0 &&
    selectedOrderBumps.length === 0 &&
    selectedShippingRates.length === 0 &&
    coupons.length === 0
  ) {
    return null;
  }

  return (
    <div className={`${_themeFormStyles.fieldset}`}>
      <div
        className={`${_themeFormStyles.fieldsetHeading} ${_themeFormStyles.withBorder}`}
      >
        Total Being Charged Today
      </div>

      {selectedProducts.map((price) => (
        <LineItem
          key={price.id}
          name={price.product.name}
          price={price.formattedUnitAmount}
        />
      ))}
      {selectedOrderBumps.map((bump) => (
        <LineItem
          key={bump.id}
          name={bump.product.name}
          price={bump.formattedUnitAmount}
        />
      ))}
      {selectedShippingRates.map((shippingRate) => (
        <LineItem
          key={shippingRate.rate_id}
          name={shippingRate.carrier_nickname}
          price={formatterWithCents.format(shippingRate.shipping_amount.amount)}
          coupon={coupons.find((c) => c.metadata.scope === 'shipping')}
        />
      ))}
      <OrderTotal total={formatterWithCents.format(totalPrice())} />
    </div>
  );
};

OrderSummary.propTypes = {
  selectedProducts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      formattedUnitAmount: PropTypes.string.isRequired,
      product: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
    }),
  ),

  selectedOrderBumps: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      formattedUnitAmount: PropTypes.string.isRequired,
      product: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
    }),
  ),

  coupons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      amount_off: PropTypes.number,
      percent_off: PropTypes.number,
    }),
  ),

  selectedShippingRates: PropTypes.arrayOf(
    PropTypes.shape({
      rate_id: PropTypes.string.isRequired,
      carrier_nickname: PropTypes.string.isRequired,
      shipping_amount: PropTypes.shape({
        amount: PropTypes.number.isRequired,
      }).isRequired,
    }),
  ),
};

OrderSummary.defaultProps = {
  selectedProducts: [],
  selectedOrderBumps: [],
  coupons: [],
  selectedShippingRates: [],
};

export default OrderSummary;
