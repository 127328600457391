import { graphql, useStaticQuery } from 'gatsby';

const useUnitedStates = () => {
  const data = useStaticQuery(graphql`
    query UnitedStatesQuery {
      allStatesJson {
        nodes {
          abbreviation
          name
        }
      }
    }
  `);

  return data.allStatesJson.nodes.map((state) => ({
    value: state.abbreviation,
    label: state.name,
  }));
};

export default useUnitedStates;
