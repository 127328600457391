import React from 'react';

import _utils from 'gatsby-theme-kalanso-ui/src/styles/utils.module.scss';

const PostScript = () => {
  return (
    <div className={`${_utils.p16x}`}>
      <p>
        <strong>PS:</strong> Did you just skip to the bottom of the page to see
        what the deal was?
        <span role="img" aria-label="Smile">
          😊
        </span>
      </p>
      <br />
      <p>
        You get{' '}
        <strong>
          25 pounds of high quality, premium biochar, The Biochar Bucket,
        </strong>{' '}
        that will improve drainage, help your soil stand up to the summer heat,
        prevent nutrient depletion, and activate your soil's natural immune
        system so you can grow any plant you want with confidence.
      </p>
      {/* <br />
      <p>
        I also show you step by step how to get started with biochar in your
        garden in the <strong>Video Crash Course</strong>... yours for only $27!
        This isn't just for vegetable gardeners, or flower gardeners. It's for
        anyone that wants to make their soil better.
      </p> */}
      <br />
      <p>Just click the blue button above right now to get started!</p>
      <br />
      <br />
    </div>
  );
};

export default PostScript;
