import React from 'react';

import Emphatic from 'gatsby-theme-kalanso-ui/src/components/text/Emphatic';
import Highlighted from 'gatsby-theme-kalanso-ui/src/components/text/Highlighted';

import Testimonial from './Testimonial';

import _styles from 'src/components/pages/order/testimonial-stack.module.scss';

const TestimonialStackLeft = () => {
  return (
    <ul className={`${_styles.list}`}>
      <Testimonial
        name="John"
        Commentary={() => (
          <>
            I got a 5 gallon bucket of your biochar and used it in all of my
            seedling transplants and again when planting into the garden.{' '}
            <Highlighted>Even as dry as this summer was,</Highlighted>{' '}
            <Emphatic>
              I had a great season gardening with huge yields of mostly peppers
              and tomatoes.
            </Emphatic>
          </>
        )}
      />

      <Testimonial
        name="Mike"
        Commentary={() => (
          <>
            <strong>I love your product</strong> and had{' '}
            <Highlighted>superb results</Highlighted> in my garden last year...
          </>
        )}
      />

      <Testimonial
        name="Chris"
        Commentary={() => (
          <>
            Lot's of fruit on the bushes.{' '}
            <Highlighted>
              Gonna be crazy. Can tell a huge difference...
            </Highlighted>
          </>
        )}
      />

      <Testimonial
        name="Mia"
        Commentary={() => (
          <>
            Once I bought the quart of biochar, I had a great excuse to start my
            garden from seed.{' '}
            <strong>
              kalanso biochar definitely gave me a confidence boost as new
              gardener...
            </strong>
          </>
        )}
      />

      <Testimonial
        name="Louis"
        Commentary={() => (
          <>
            Seed to sprout in 3 days!{' '}
            <strong>Now I don't feel like a plant killer...</strong>"
          </>
        )}
      />

      <Testimonial
        name="Susan"
        Commentary={() => (
          <>
            I used biochar in my raised vegetable beds this year, and I think it
            really helped! I think it also had a positive impact on my several{' '}
            <Emphatic>perennial/shrub borders.</Emphatic>
          </>
        )}
      />
    </ul>
  );
};

export default TestimonialStackLeft;
