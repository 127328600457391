import React from 'react';

import { Notifier } from '@airbrake/browser';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };

    if (process.env.GATSBY_ACTIVE_ENV === 'production') {
      this.airbrake = new Notifier({
        projectId: process.env.GATSBY_AIRBRAKE_PROJECT_ID,
        projectKey: process.env.GATSBY_AIRBRAKE_PROJECT_KEY,
      });
    }
  }

  componentDidCatch(error, errorInfo) {
    // Fallback UI
    this.setState({ hasError: true });

    if (this.airbrake) {
      // Send error to airbrake
      this.airbrake.notify({
        error: error,
        params: { info: errorInfo },
      });
    }
  }

  render() {
    if (this.state.hasError) {
      if (this.props.FallbackComponent) {
        const { FallbackComponent } = this.props;
        return <FallbackComponent />;
      }

      return (
        <p>
          An error has ocurred and we have been automatically notified. We
          appologize for the inconvenience. Please reach out to us at{' '}
          <a href="mailto:info@kalanso.com">info@kalanso.com</a> for assistence.
        </p>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
