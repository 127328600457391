import React from 'react';
import PropTypes from 'prop-types';

import _themeOrderSummaryStyles from './order-summary.module.scss';

const OrderTotal = ({ title, total }) => {
  return (
    <div className={`${_themeOrderSummaryStyles.lineItem} ${_themeOrderSummaryStyles.total}`}>
      <div className={`${_themeOrderSummaryStyles.lineItemTitle}`}>
        {title}
      </div>
      <div className={`${_themeOrderSummaryStyles.lineItemPrice}`}>
        {total}
      </div>
    </div>
  );
};

OrderTotal.propTypes = {
  title: PropTypes.string,
  total: PropTypes.string.isRequired,
};

OrderTotal.defaultProps = {
  title: 'Total',
};

export default OrderTotal;
