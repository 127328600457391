import { useEffect } from 'react';
import { useQueryParams, StringParam } from 'use-query-params';
import { useFetch } from 'kalanso-plugin-hooks';

const useCouponApi = () => {
  const [{ couponId }] = useQueryParams({
    couponId: StringParam,
  });

  const [{ payload, ...api }, getCoupon] = useFetch(
    `/api/fetch-coupon?couponId=${couponId}`,
    {
      method: 'GET',
    },
  );

  // Test free shipping: http://localhost:8888/go?couponId=aZFKdEkj
  useEffect(() => {
    if (couponId) {
      getCoupon();
    }
  }, [couponId, getCoupon]);

  return {
    ...api,
    coupon: payload && payload.coupon ? payload.coupon : undefined,
  };
};

export default useCouponApi;
