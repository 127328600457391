import { useContext } from 'react';

import { OrderFormContext, ACTIONS } from './OrderFormProvider';

const useOrderForm = () => {
  const [state, dispatch] = useContext(OrderFormContext);

  const transitionTo = (step) => {
    dispatch({ type: ACTIONS.STEP, step });
  };

  const setFormValues = (step, values) => {
    dispatch({ type: ACTIONS.SET_FORM_VALUES, step, values });
  }

  return [
    state,
    {
      transitionTo,
      setFormValues,
    },
  ];
};

export default useOrderForm;
