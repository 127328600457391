import React from 'react';

import Emphatic from 'gatsby-theme-kalanso-ui/src/components/text/Emphatic';
import Highlighted from 'gatsby-theme-kalanso-ui/src/components/text/Highlighted';

import Testimonial from './Testimonial';

import _styles from 'src/components/pages/order/testimonial-stack.module.scss';

const TestimonialStackRight = () => {
  return (
    <ul className={`${_styles.list}`}>
      <Testimonial
        name="Christel"
        Commentary={() => (
          <>
            <Emphatic>
              My tomatoes are the best looking in my community garden...
            </Emphatic>
          </>
        )}
      />

      <Testimonial
        name="Eric"
        Commentary={() => (
          <>
            <Highlighted>
              Biggest thing i noticed was there was no disease
            </Highlighted>{' '}
            on the plants i used kalanso biochar with... no weird fungus
            either... everything looks healthy...{' '}
            <Emphatic>FINALLY!!! LOVE IT!!</Emphatic>
          </>
        )}
      />
    </ul>
  );
};

export default TestimonialStackRight;
