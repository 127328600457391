import React, { useState } from 'react';

import _themeFormStyles from '../../forms/forms.module.scss';
import _themeOrderFormStyles from '../order-form.module.scss';

// See: https://stripe.com/docs/testing
// Not auth: 4242 4242 4242 4242
// Auth required: 4000 0027 6000 3184
// Regulatory: https://stripe.com/docs/testing#regulatory-cards

const cardStyle = {
  style: {
    base: {
      color: '#000000',
      fontFamily: 'Montserrat, Arial, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '20.88px',
      '::placeholder': {
        color: '#000000',
      },
    },
    invalid: {
      color: '#ce3100',
      iconColor: '#ce3100',
    },
  },
};

const CreditCard = ({
  CardElement,
  register,
  setError,
  clearErrors,
  setValue,
  errors,
}) => {

  const [isPaymentComplete, setIsPaymentComplete] = useState(false);

  const handlePaymentMethodChange = (event) => {
    // FYI: This is a Stripe event object
    const { complete, error } = event;

    if (error) {
      setError('paymentMethod', {
        type: 'manual',
        message: error.message || 'Invalid payment details.',
      });
    } else {
      clearErrors('paymentMethod');
    }

    setValue('paymentComplete', complete, {
      shouldDirty: true,
      shouldValidate: Boolean(complete),
    });

    setIsPaymentComplete(complete);
  };

  return (
    <div className={`${_themeFormStyles.field} ${_themeOrderFormStyles.field}`}>
      <div
        className={`${_themeFormStyles.input} ${_themeFormStyles.payment} ${
          isPaymentComplete ? _themeFormStyles.valid : ''
        } ${errors.length > 0 ? _themeFormStyles.invalid : ''}`}
      >
        <input
          type="hidden"
          name="paymentComplete"
          ref={register}
        />
        <CardElement
          id="card-element"
          options={cardStyle}
          onChange={handlePaymentMethodChange}
        />
      </div>
      <div
        id="payment-error-message"
        className={`${_themeFormStyles.errorMessage}`}
      >
        {errors.map((error) => error.message).join(' ')}
      </div>
    </div>
  );
};

export default CreditCard;