import React from 'react';
import PropTypes from 'prop-types';

import TableRow from '../../table/TableRow';

import _themeOrderSelectStyles from './product-select.module.scss';
import _utils from '../../../styles/utils.module.scss';

const ProductOption = ({ price, name, register, disabled }) => {
  const registerRef = Boolean(register) ? register : null;

  return (
    <TableRow
      className={`${_themeOrderSelectStyles.item} ${
        price.isMostPopular ? _themeOrderSelectStyles.mostPopular : ''
      }`}
    >
      <label
        htmlFor={`${name}-${price.id}`}
        className={`${_utils.flex} ${_utils.flexGrow1} ${_utils.flexJustifyBetween}`}
      >
        <div className={`${_themeOrderSelectStyles.orderSelectOptionContent}`}>
          <input
            id={`${name}-${price.id}`}
            name={name}
            value={price.id}
            type="radio"
            className={`${_themeOrderSelectStyles.radio}`}
            ref={registerRef}
            disabled={disabled}
          />

          <div className={`${_themeOrderSelectStyles.orderSelectOptionLabel}`}>
            {price.isMostPopular ? (
              <span className={`${_themeOrderSelectStyles.mostPopularLabel}`}>
                MOST POPULAR!
              </span>
            ) : null}
            <span
              className={`${_themeOrderSelectStyles.orderSelectOptionTitle}`}
            >
              {price.product.name}
            </span>
          </div>
        </div>
        <div className={`${_themeOrderSelectStyles.orderSelectOptionPrice}`}>
          {price.formattedUnitAmount}
        </div>
      </label>
    </TableRow>
  );
};

ProductOption.propTypes = {
  price: PropTypes.shape({
    id: PropTypes.string.isRequired,
    formattedUnitAmount: PropTypes.string.isRequired,
    isMostPopular: PropTypes.bool.isRequired,
    product: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

ProductOption.defaultProps = {
  isChecked: false,
};

export default ProductOption;
