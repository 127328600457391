import React, { useEffect, useCallback } from 'react';

import useOrderForm from './useOrderForm';
import { STEPS } from './OrderFormProvider';

import { useForm } from 'react-hook-form';
import { useFetch } from 'kalanso-plugin-hooks';
import { useTrackingCodes } from 'kalanso-plugin-hooks';

import { joiResolver } from '@hookform/resolvers/joi';

import useCustomerToken from 'src/hooks/useCustomerToken';
import useStripeProducts from 'src/hooks/useStripeProducts';

import LinkButton from 'gatsby-theme-kalanso-ui/src/components/forms/buttons/LinkButton';
import Help from 'gatsby-theme-kalanso-ui/src/components/text/Help';

import RadioToggleInput from 'gatsby-theme-kalanso-ui/src/components/forms/inputs/RadioToggleInput';
import TextField from 'gatsby-theme-kalanso-ui/src/components/forms/inputs/TextField';
import Honeypot from 'gatsby-theme-kalanso-ui/src/components/forms/inputs/Honeypot';
import Submit from 'gatsby-theme-kalanso-ui/src/components/forms/inputs/Submit';
import AddressFormFields from 'gatsby-theme-kalanso-ui/src/components/order-forms/shipping-select/AddressFormFields';
import ProductSelect from 'gatsby-theme-kalanso-ui/src/components/order-forms/product-select/ProductSelect';

import FormProgressBar from './FormProgressBar';

import _themeFormStyles from 'gatsby-theme-kalanso-ui/src/components/forms/forms.module.scss';
import _themeOrderFormStyles from 'gatsby-theme-kalanso-ui/src/components/order-forms/order-form.module.scss';
import _utils from 'gatsby-theme-kalanso-ui/src/styles/utils.module.scss';
import _progressStyles from './progress.module.scss';

import BaseJoi from 'joi';

import PhoneNumberExtension from 'joi-phone-number';
import { canUseDOM } from 'kalanso-plugin-utils';

// Not sure how to combine multiple extensions
const custom = BaseJoi.extend(PhoneNumberExtension);

const requiredWhenBillingIsDifferent = custom
  .string()
  .when('differentShippingAddress', {
    is: custom.string().valid('true'),
    then: custom.string().required(),
    otherwise: custom.forbidden(),
  });

const schema = custom.object({
  differentShippingAddress: custom.string(),
  priceId: custom.string().required(),
  honey: custom.string().min(0).max(0).allow('').required(),
  billing: custom.object({
    firstName: custom.string().required(),
    lastName: custom.string().required(),
    phone: custom.string().phoneNumber().required(),
    email: custom
      .string()
      .required()
      .email({ minDomainSegments: 2, tlds: { allow: false } })
      .max(255),
    line1: custom.string().required(),
    line2: custom.string().allow(''),
    city: custom.string().required(),
    state: custom.string().required(),
    zip: custom.string().required(),
    country: custom.string().required(),
  }),
  shipping: custom.object({
    firstName: requiredWhenBillingIsDifferent,
    lastName: requiredWhenBillingIsDifferent,
    line1: requiredWhenBillingIsDifferent,
    line2: custom.string().when('differentShippingAddress', {
      is: custom.string().valid('true'),
      then: custom.string().allow(''),
      otherwise: custom.forbidden(),
    }),
    city: requiredWhenBillingIsDifferent,
    state: requiredWhenBillingIsDifferent,
    zip: requiredWhenBillingIsDifferent,
    country: requiredWhenBillingIsDifferent,
  }),
});

const InitiateCheckoutForm = () => {
  const getDefaultValues = (key) => {
    if (canUseDOM()) {
      return JSON.parse(window.localStorage.getItem(key)) || {};
    }
  };

  const setDefaultValues = (key, data) => {
    if (canUseDOM()) {
      return window.localStorage.setItem(key, JSON.stringify(data));
    }
  };

  const [, actions] = useOrderForm();

  const { transitionTo, setFormValues } = actions;

  const { prices } = useStripeProducts();

  const trackingCodes = useTrackingCodes();

  const formMethods = useForm({
    resolver: joiResolver(schema),
    mode: 'onBlur',
    defaultValues: getDefaultValues('__INITIATE_CHECKOUT__'),
  });

  // This is an empty object on the first render
  const formValues = formMethods.watch();
  const isShippingAddressDifferent =
    formValues['differentShippingAddress'] === 'true';

  const { token, writeToken } = useCustomerToken();

  const [customerApi, initiateCheckout] = useFetch('/api/initiate-checkout', {
    method: 'POST',
    body: JSON.stringify({
      ...formValues,
      metadata: {
        ...trackingCodes,
      }
    }),
    headers: {
      authorization: `Bearer ${token}`,
    },
  });

  const { clientToken } = customerApi.payload || {};

  const {
    errors: customerApiErrors,
    success: customerApiSuccess,
    pending: customerApiPending,
  } = customerApi;

  const { setError } = formMethods;

  useEffect(() => {
    if (customerApiErrors) {
      setError('server', { type: 'manual' });
    }
  }, [customerApiErrors, setError]);

  useEffect(() => {
    if (clientToken) {
      writeToken(clientToken);
    }
  }, [clientToken, writeToken]);

  useEffect(() => {
    if (customerApiSuccess) {
      transitionTo(STEPS.COMPLETE_CHECKOUT);
    }
  }, [customerApiSuccess, transitionTo]);

  const onSubmit = useCallback(
    (data) => {
      if (!customerApiPending) {
        setDefaultValues('__INITIATE_CHECKOUT__', data);
        setFormValues('INITIATE_CHECKOUT', data);
        initiateCheckout();
      }
    },
    [customerApiPending, initiateCheckout, setFormValues],
  );

  return (
    <>
      <div
        className={`${_progressStyles.headlineContainer} ${_utils.pl16x} ${_utils.pr16x}`}
      >
        <h1 className={`${_progressStyles.headline}`}>
          STEP #1: How many BUCKETS and where do we send them?
        </h1>
      </div>

      <FormProgressBar />

      <form
        className={`${_utils.mb64x}`}
        onSubmit={formMethods.handleSubmit(onSubmit)}
      >
        {/* Product Select */}
        <div className={`${_themeFormStyles.fieldset}`}>
          <div className={`${_themeFormStyles.fieldsetHeading}`}>
            How Many BUCKETS Do You Want?
          </div>

          <ProductSelect
            prices={prices}
            name="priceId"
            errors={formMethods.errors}
            register={formMethods.register}
            disabled={customerApiPending}
          />
        </div>

        {/* Contact details / Billing address */}
        <div className={`${_themeFormStyles.fieldset}`}>
          <div className={`${_themeFormStyles.fieldsetHeading}`}>
            Enter Your Contact Information
          </div>

          <TextField
            id="firstName"
            label="First Name *"
            name="billing.firstName"
            register={formMethods.register}
            errors={formMethods.errors}
            values={formValues}
          />

          <TextField
            id="lastName"
            label="Last Name *"
            name="billing.lastName"
            register={formMethods.register}
            errors={formMethods.errors}
            values={formValues}
          />

          <TextField
            label="Email Address *"
            id="email"
            name="billing.email"
            type="email"
            register={formMethods.register}
            errors={formMethods.errors}
            values={formValues}
          />

          <TextField
            label="Phone Number *"
            id="phone"
            name="billing.phone"
            type="tel"
            register={formMethods.register}
            errors={formMethods.errors}
            values={formValues}
          />
        </div>

        {/* Billing Address */}
        <div className={`${_themeFormStyles.fieldset}`}>
          <div className={`${_themeFormStyles.fieldsetHeading}`}>
            Enter Your Billing Address
          </div>

          <AddressFormFields
            register={formMethods.register}
            errors={formMethods.errors}
            formValues={formValues}
            line1="billing.line1"
            line2="billing.line2"
            city="billing.city"
            state="billing.state"
            zip="billing.zip"
            country="billing.country"
          />
        </div>

        <div className={`${_themeFormStyles.fieldset}`}>
          <div className={`${_themeFormStyles.fieldsetHeading}`}>
            Enter The Shipping Address
          </div>
          <div
            className={`${_themeFormStyles.field} ${_themeOrderFormStyles.field}`}
          >
            <RadioToggleInput
              name="differentShippingAddress"
              disabled={false}
              defaultCheckedIndex={isShippingAddressDifferent ? 1 : 0}
              register={formMethods.register}
              options={[
                {
                  label: 'Same as billing address',
                  value: 'false',
                },
                {
                  label: 'Use a different shipping address',
                  value: 'true',
                },
              ]}
            />
            {isShippingAddressDifferent ? (
              <div className={_themeFormStyles.billingTo}>
                <AddressFormFields
                  register={formMethods.register}
                  errors={formMethods.errors}
                  formValues={formValues}
                  firstName={`shipping.firstName`}
                  lastName={`shipping.lastName`}
                  line1={`shipping.line1`}
                  line2={`shipping.line2`}
                  city={`shipping.city`}
                  state={`shipping.state`}
                  country={`shipping.country`}
                  zip={`shipping.zip`}
                />
              </div>
            ) : null}
          </div>
        </div>

        <Honeypot register={formMethods.register} />

        {formMethods.errors.server && (
          <div className={`${_themeFormStyles.fieldset} ${_utils.mt32x}`}>
            <div className={`${_utils.pr16x} ${_utils.pl16x}`}>
              <div
                className={`${_themeFormStyles.errors} ${_utils.pt32x} ${_utils.pb32x}`}
              >
                <p className={`${_themeFormStyles.errorMessage}`}>
                  Ohh no! Something went wrong. Please refresh your browser and
                  try again...
                </p>
                <p className={`${_themeFormStyles.errorMessage}`}>
                  If the problem persists, send an email to support@kalanso.com
                </p>
              </div>
            </div>
          </div>
        )}

        {/* Submit Button */}
        <div className={`${_themeFormStyles.fieldset} ${_utils.mt32x}`}>
          <Submit
            disabled={customerApiPending}
            pending={customerApiPending}
            pendingText="Processing..."
            idleText="YES! Take Me To Checkout!"
            idleIcon="fa-arrow-right"
            idleSubtext="I Want To Grow Whatever I Want With Biochar!"
          />

          {!customerApiPending && (
            <div
              className={`${_utils.mt8x} ${_utils.mb32x} ${_utils.textCenter}`}
            >
              <LinkButton type="submit">
                YES! I WANT TO ORDER MY BIOCHAR NOW
              </LinkButton>
            </div>
          )}
        </div>

        <div className={`${_themeFormStyles.fieldset}`}>
          <Help
            className={`${_utils.ml32x} ${_utils.mr32x} ${_utils.textCenter}`}
          >
            * When you WHACK THE BUTTON up there{' '}
            <span role="img" aria-label="Point Up">
              👆
            </span>{' '}
            ️you will be taken to a secure checkout form where you will choose
            your shipping rate and your payment method. Your information is
            secure and will not be shared.
          </Help>
        </div>
      </form>
    </>
  );
};

export default InitiateCheckoutForm;
