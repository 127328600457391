import React from 'react';

import ArtDirectedBackground from 'src/components/art-direction/CollageOne';

import { LogoCentered } from 'kalanso-plugin-logos';

import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import FreeShippingBanner from './FreeShippingBanner';
import PostScript from './PostScript';
import OfferStack from './OfferStack';
import TestimonialStackLeft from './TestimonialStackLeft';
import TestimonialStackRight from './TestimonialStackRight';
import Footer from 'gatsby-theme-kalanso-ui/src/components/footer/Footer';

import OrderFormProvider from './OrderFormProvider';
import InitiateCheckoutForm from './InitiateCheckoutForm';
import CompleteCheckoutForm from './CompleteCheckoutForm';

import Container from 'gatsby-theme-kalanso-ui/src/components/layout/Container';
import Row from 'gatsby-theme-kalanso-ui/src/components/layout/Row';
import Col from 'gatsby-theme-kalanso-ui/src/components/layout/Col';

import ErrorBoundary from 'gatsby-theme-kalanso-ui/src/components/forms/ErrorBoundary';

import _offerSheetStyles from 'src/components/pages/order/offer-sheet.module.scss';

const OrderPage = (props) => {
  const imageData = useStaticQuery(
    graphql`
      query {
        productImage: file(
          relativePath: { eq: "buckets-01--landscape--2560x1586.jpg" }
        ) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        renewableCarbonImage: file(
          relativePath: { eq: "100-percent-renewable-carbon--940x788.png" }
        ) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        almostDoneImage: file(
          relativePath: { eq: "almost-done--1440x1182.png" }
        ) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `,
  );

  return (
    <OrderFormProvider>
      {({ currentStep, isEligibleForFreeShipping }) => {
        return (
          <>
            <Container
              fullScreen={true}
              Background={ArtDirectedBackground}
              className={`${_offerSheetStyles.positionBeneathNextSibling}`}
            >
              <Row justify="Center">
                <Col>
                  <header className={`${_offerSheetStyles.header}`}>
                    <LogoCentered />
                  </header>
                </Col>
              </Row>
            </Container>

            <Container
              className={`${_offerSheetStyles.positionAbovePreviousSibling}`}
            >
              <Row className={`${_offerSheetStyles.sheet}`}>
                <Col>
                  {isEligibleForFreeShipping && <FreeShippingBanner />}
                  <div
                    className={`${_offerSheetStyles.offer} ${
                      isEligibleForFreeShipping
                        ? _offerSheetStyles.noBorderTopRadius
                        : ''
                    }`}
                  >
                    <div className={`${_offerSheetStyles.grid}`}>
                      <div>
                        <OfferStack>
                          <div
                            className={`${_offerSheetStyles.roundedImageWrapper}`}
                          >
                            <Img
                              fluid={
                                imageData.productImage.childImageSharp.fluid
                              }
                            />
                          </div>
                        </OfferStack>
                        <TestimonialStackLeft />
                        <TestimonialStackRight />
                        <Img
                          fluid={
                            imageData.renewableCarbonImage.childImageSharp.fluid
                          }
                        />
                      </div>
                      <div>
                        <ErrorBoundary>
                          {currentStep === 0 && (
                            <>
                              <InitiateCheckoutForm />
                            </>
                          )}
                          {currentStep === 1 && (
                            <>
                              <CompleteCheckoutForm />
                              <Img
                                fluid={
                                  imageData.almostDoneImage.childImageSharp
                                    .fluid
                                }
                              />
                            </>
                          )}
                        </ErrorBoundary>
                        <PostScript />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>

            <Footer hideLinks={true} />
          </>
        );
      }}
    </OrderFormProvider>
  );
};

export default OrderPage;
