import React from 'react';

import _themeFormStyles from '../../forms/forms.module.scss';
import _utils from '../../../styles/utils.module.scss';

const RadioToggleInput = ({ name, disabled, options, defaultCheckedIndex, register }) => {
  return (
    <>
      {options.map((option, index) => (
        <div
          key={index}
          className={`${_themeFormStyles.input} ${_themeFormStyles.shippingMethod}`}
        >
          <label
            htmlFor={`${name}-${index}`}
            className={`${_utils.flexRow} ${_utils.flexAlignCenter}`}
          >
            <input
              id={`${name}-${index}`}
              name={name}
              type="radio"
              ref={register}
              value={option.value}
              disabled={disabled}
              defaultChecked={defaultCheckedIndex === index}
            />
            <div
              className={`${_utils.flexRow} ${_utils.flexGrow1} ${_utils.flexJustifyBetween} ${_utils.pl16x} ${_utils.pr16x}`}
            >
              <div className={`${_utils.flexColumn}`}>{option.label}</div>
            </div>
          </label>
        </div>
      ))}
    </>
  );
};

export default RadioToggleInput;
