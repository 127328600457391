import React from 'react';

import Highlighted from 'gatsby-theme-kalanso-ui/src/components/text/Highlighted';

import _stackStyles from './stack.module.scss';
import _utils from 'gatsby-theme-kalanso-ui/src/styles/utils.module.scss';

import currencyFormatter from 'src/utils/currencyFormatter';

const offers = [
  {
    title: 'Buy Two Buckets...',
    value: 'TAKE 5% OFF...',
  },
  {
    title: 'Buy Three Buckets...',
    value: 'TAKE 10% OFF...',
  },
  {
    title: 'Buy Four Buckets...',
    value: 'TAKE 15% OFF...',
  },
  {
    title: 'Buy Five Buckets...',
    value: 'TAKE 20% OFF...',
  },
];

const OfferStack = ({ children }) => {
  return (
    <div className={`${_stackStyles.offerStack}`}>
      <h1 className={`${_stackStyles.title}`}>
        <span className={`${_stackStyles.preheadline}`}>
          <em>Ready To Defeat Bad Soil Once And For All?</em>
        </span>
        <br />
        <br />
        <span className={`${_stackStyles.headline}`}>
          <Highlighted>Order Your Biochar Bucket Now For Just $137</Highlighted>
        </span>
        <br />
        <br />
        <span className={`${_stackStyles.edition}`}>
          And Get More At A Discount
        </span>
      </h1>
      <div className={`${_utils.flexRowCentered}`}>
        <hr
          className={`${_stackStyles.divider} ${_stackStyles.dividerSmall}`}
        />
      </div>
      <ul className={`${_stackStyles.stack}`}>
        {offers.map((offer, index) => (
          <li key={index} className={`${_stackStyles.stackItem}`}>
            <div className={`${_stackStyles.stackItemContent}`}>
              <span className={`${_stackStyles.icon}`}>
                <i className="fas fa-check"></i>
              </span>
              <span className={`${_stackStyles.stackItemTitle}`}>
                {offer.title}
              </span>
            </div>
            <div className={`${_stackStyles.stackItemValue}`}>
              {typeof offer.value === 'number'
                ? currencyFormatter.format(offer.value)
                : offer.value}
            </div>
          </li>
        ))}
      </ul>
      {children}
      <h2 className={`${_stackStyles.totalValue}`}>
        "YES! I Want The Biochar Bucket NOW!"
      </h2>
      <div className={`${_utils.flexRowCentered}`}>
        <hr
          className={`${_stackStyles.divider} ${_stackStyles.dividerLarge}`}
        />
      </div>
    </div>
  );
};

OfferStack.propTypes = {};

OfferStack.defaultProps = {};

export default OfferStack;
