import React from 'react';
import PropTypes from 'prop-types';

import { formatterWithCents } from 'src/utils/currencyFormatter';

import _themeFormStyles from '../../forms/forms.module.scss';
import _themeOrderFormStyles from '../../order-forms/order-form.module.scss';
import _shippingStyles from './shipping.module.scss';
import _utils from '../../../styles/utils.module.scss';

import { ErrorMessage } from '@hookform/error-message';

export const formatDeliveryTime = (deliveryDays) => {
  if (deliveryDays === 1) {
    return `1 Business Day`;
  } else if (deliveryDays === null) {
    return `3-5 Business Days`;
  } else {
    return `${deliveryDays} Business Days`;
  }
};

export const IdleShippingState = () => {
  return (
    <div className={`${_themeFormStyles.field} ${_themeOrderFormStyles.field}`}>
      <div
        className={`${_themeFormStyles.input} ${_shippingStyles.shippingMethod}`}
      >
        <div className={`${_utils.flexRow}`}>
          <div
            className={`${_utils.flexRow} ${_utils.flexGrow} ${_utils.flexJustifyBetween} ${_utils.pl16x} ${_utils.pr16x}`}
          >
            <div className={`${_utils.flexColumn}`}>
              <em>
                Select How Much BIOCHAR You Want FIRST And We'll Work Hard To
                Find You The Lowest Shipping Rates...
              </em>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const FetchingShippingState = ({ isEligibleForFreeShipping }) => {
  return (
    <div className={`${_themeFormStyles.field} ${_themeOrderFormStyles.field}`}>
      <div
        className={`${_themeFormStyles.input} ${_shippingStyles.shippingMethod}`}
      >
        <div className={`${_utils.flexRow}`}>
          <div
            className={`${_utils.flexRow} ${_utils.flexGrow} ${_utils.flexJustifyBetween} ${_utils.pl16x} ${_utils.pr16x}`}
          >
            <div className={`${_utils.flexRow}`}>
                <i className={`fas fa-spinner-third fa-spin`}></i>
              <span>
                {isEligibleForFreeShipping
                  ? 'Seeing how much $$$ you saved... ;)'
                  : 'Finding lowest rates...'}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ErrorShippingState = () => {
  return (
    <div className={`${_themeFormStyles.field} ${_themeOrderFormStyles.field}`}>
      <div
        className={`${_themeFormStyles.input} ${_shippingStyles.shippingMethod}`}
      >
        <div className={`${_utils.flexRow}`}>
          <div
            className={`${_utils.flexRow} ${_utils.flexGrow} ${_utils.flexJustifyBetween} ${_utils.pl16x} ${_utils.pr16x}`}
          >
            <div className={`${_utils.flexColumn}`}>
              <span>
                There were errors getting rates for your address. Please check
                your address and try again.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const RatesList = ({ name, rates, register, disabled }) => {
  return (
    <div className={`${_themeFormStyles.field} ${_themeOrderFormStyles.field}`}>
      {rates.map((rate) => (
        <div
          key={rate.rate_id}
          className={`${_themeFormStyles.input} ${_shippingStyles.shippingMethod}`}
        >
          <label
            htmlFor={`${name}-${rate.rate_id}`}
            className={`${_utils.flexRow}`}
          >
            <input
              id={`${name}-${rate.rate_id}`}
              name={name}
              type="radio"
              ref={register}
              value={rate.rate_id}
              disabled={disabled}
            />
            <div
              className={`${_utils.flexRow} ${_utils.flexGrow1} ${_utils.flexJustifyBetween} ${_utils.pl16x} ${_utils.pr16x}`}
            >
              <div className={`${_utils.flexColumn}`}>
                <span>{rate.service_type}</span>
                <small>{formatDeliveryTime(rate.delivery_days)}</small>
              </div>
              <div>
                <strong>
                  {formatterWithCents.format(rate.shipping_amount.amount)}
                </strong>
              </div>
            </div>
          </label>
        </div>
      ))}
    </div>
  );
};

const ShippingSelect = (props) => {
  const {
    shippingApi,
    register,
    name,
    errors,
    disabled,
    isEligibleForFreeShipping,
  } = props;

  const { rates } = shippingApi;

  return (
    <>
      {shippingApi.idle && rates.length === 0 && <IdleShippingState />}
      {shippingApi.pending && (
        <FetchingShippingState
          isEligibleForFreeShipping={isEligibleForFreeShipping}
        />
      )}
      {shippingApi.errors && <ErrorShippingState />}
      {(shippingApi.success || (shippingApi.idle && rates.length > 0)) && (
        <RatesList
          name={name}
          rates={rates}
          register={register}
          disabled={disabled}
        />
      )}

      <div
        id={`${name}-error-message`}
        className={`${_themeFormStyles.errorMessage} ${_utils.pl32x}`}
      >
        <ErrorMessage errors={errors} name={name} />
      </div>
    </>
  );
};

ShippingSelect.propTypes = {
  name: PropTypes.string.isRequired,
  shippingApi: PropTypes.shape({
    idle: PropTypes.bool,
    pending: PropTypes.bool,
    success: PropTypes.bool,
    errors: PropTypes.bool,
    rates: PropTypes.arrayOf(
      PropTypes.shape({
        rate_id: PropTypes.string.isRequired,
        delivery_days: PropTypes.number.isRequired,
        shipping_amount: PropTypes.shape({
          amount: PropTypes.number,
        }),
      }),
    ),
  }),
  disabled: PropTypes.bool,
};

export default ShippingSelect;
